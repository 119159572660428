#sb-title-inner,
#sb-info-inner,
#sb-loading-inner,
div.sb-message
    font-weight: 200;
    color: #fff;
#sb-container
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
    margin: 0;
    padding: 0;
    text-align: left;
#sb-overlay
    position: relative;
    width: 100%;
    height: 100%;
#sb-wrapper
    position: absolute;
    visibility: hidden;
    width: 100px;
#sb-wrapper-inner
    position: relative;
    overflow: hidden;
    height: 100px;
    // border: 1px solid #303030;
#sb-body
    position: relative;
    height: 100%;
#sb-body-inner
    position: absolute;
    width: 100%;height: 100%;
#sb-player.html
    overflow: auto;
    height: 100%;
#sb-body img
    border: none;
#sb-loading
    position: relative;
    height: 100%;
#sb-loading-inner
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 24px;
    margin-top: -12px;
    text-align: center;
#sb-loading-inner span
    display: inline-block;
    padding-left: 34px;
    // background: url('../img/shadowbox/loading.gif') no-repeat;
#sb-body,
#sb-loading
    // background-color: #060606;
#sb-title,
#sb-info
    position: relative;overflow: hidden;margin: 0;padding: 0;
#sb-title,
#sb-title-inner
    line-height: 26px;
    height: 26px;
#sb-title-inner
    font-size: 16px;
#sb-info,
#sb-info-inner
    line-height: 20px;
    height: 20px;
#sb-info-inner
    font-size: 12px;
#sb-nav
    float: right;
    width: 45%;
    height: 16px;
    padding: 2px 0;
#sb-nav a
    display: block;
    float: right;
    width: 16px;
    height: 16px;
    margin-left: 3px;
    cursor: pointer;
    background-repeat: no-repeat;
#sb-nav-close
    border: none;
    background-image: url('../img/shadowbox/close.png');
#sb-nav-next
    background-image: url('../img/shadowbox/next.png');
#sb-nav-previous
    background-image: url('../img/shadowbox/previous.png');
#sb-nav-play
    background-image: url('../img/shadowbox/play.png');
#sb-nav-pause
    background-image: url('../img/shadowbox/pause.png');
#sb-counter
    float: left;
    width: 45%;
#sb-counter a
    padding: 0 4px 0 0;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
#sb-counter a.sb-counter-current
    text-decoration: underline;
div.sb-message
    font-size: 12px;
    padding: 10px;
    text-align: center;
div.sb-message a:link,
div.sb-message a:visited
    text-decoration: underline;
    color: #fff;
